<template>
  <div class="collectDetail">
    <div class="detailCon">
      <div class="top">
        <img src="../assets/back.png" alt="" @click="goBack()" class="back" />
        <img class="goodsImage" :src="goodsDetailData.image" alt="" />
        <img src="../assets/zuo.png" alt="" class="goodsZuo" />
      </div>
      <div class="bot">
        <div class="botLeft">{{ goodsDetailData.name }}</div>
        <div class="botRight">
          <p><span>￥</span>{{ goodsDetailData.price }}</p>
        </div>
      </div>
    </div>
    <div class="detailAuthor">
      <div class="autLeft">
        <img :src="goodsDetailData.author_image" alt="" class="autImg" />
        <div class="autName">
          <p>{{ goodsDetailData.author_name }}</p>
          <img src="../assets/rz.png" alt="" />
        </div>
      </div>
    </div>
    <div class="detailPart">
      <img src="../assets/titleLeft.png" alt="" />
      <h1>认证信息</h1>
      <img src="../assets/titleRight.png" alt="" />
    </div>
    <div class="detailRzxx">
      <div class="rzxxItem">
        <div class="rzxx-left">标识</div>
        <div class="rzxx-center">{{ goodsDetailData.asset_id }}</div>
        <div class="rzxx-right" v-if="goodsDetailData.asset_id != null">
          <button class="tag-read" :data-clipboard-text="copyText" @click="copy(goodsDetailData.asset_id)">
            <img src="../assets/copy.png" alt="" />
          </button>
        </div>
      </div>
      <div class="rzxxItem">
        <div class="rzxx-left">交易凭证</div>
        <div class="rzxx-center">{{ goodsDetailData.tx_id }}</div>
        <div class="rzxx-right" v-if="goodsDetailData.tx_id != null">
          <button class="tag-read" :data-clipboard-text="copyText" @click="copy(goodsDetailData.tx_id)">
            <img src="../assets/copy.png" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div class="detailPart detailPart1" v-if="goodsDetailData.content != ''">
      <img src="../assets/titleLeft.png" alt="" />
      <h1>藏品介绍</h1>
      <img src="../assets/titleRight.png" alt="" />
    </div>
    <div class="detailCpjs" v-if="goodsDetailData.content != ''" v-html="goodsDetailData.content"></div>
    <div class="detailGmxz">
      <div class="detailPart detailPart2">
        <img src="../assets/titleLeft.png" alt="" />
        <h1>购买须知</h1>
        <img src="../assets/titleRight.png" alt="" />
      </div>
      <p>
        获得此数藏空投权限的准车主，可以购买，每个账号只能购买一份，购买后可在【车讯数藏—我的】中查看。

        本期数字藏品都有唯一编号，且不可篡改。数字藏品的版权归属不随交易而变更，除用户获得版权方的书面同意外，用户不得将数手藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。该数字藏品一经售出，将不支持退换。

        数字藏品仅支持14周岁及以上用户购买，请您购买前完成实名认证。为了保证数字藏品能顺利发放到您的账号，请您通过【车讯数藏】平台购买数字藏品。

        请注意:请您空投到数字藏品后，尽快完成支付。未在2分钟内完成支付的，会被视为无效订单或自行放弃购买资格，此时库存会被释放。若成功完成支付，该数字藏品将会下发到您的账号。受网影响，部分藏品上链速度较慢，请耐心等待。
      </p>
      <!-- <div class="gmxzLjgm">立即购买</div> -->
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { reactive, toRefs, onUnmounted, getCurrentInstance } from "vue";
import { getCollectDetail } from "../api/detail.js";
import { Toast } from "@nutui/nutui";
export default {
  data() {
    return {
      id: "",
      goodsDetailData: {},
      timeStart: false,
      copyText: "",
    };
  },
  setup() {
    const dataObj = getCurrentInstance();
    const state = reactive({
      page: 0,
      timeOut: "",
    });
    onUnmounted(() => {
      if (dataObj.data.goodsDetailData.state == 4) {
        clearInterval(state.timeOut);
        state.timeOut = "";
      }
    });
  },
  mounted() {
    console.log(this.$route.query);
    this.id = this.$route.query.id;
    this.getGoodsDetailData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    copy(item) {
      this.copyText = item;
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        Toast.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        Toast.fail("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    getGoodsDetailData() {
      let params = {
        id: this.id,
      };
      getCollectDetail(params).then((res) => {
        console.log(res.data);
        this.goodsDetailData = res.data;
        if (this.goodsDetailData.status == 4) {
          this.timeOut = setInterval(() => {
            if (this.goodsDetailData.diff_time <= 0) {
              this.goodsDetailData.status = 1;
              this.timeStart = false;
            } else {
              this.timeStart = true;
              let second_time = parseInt(this.goodsDetailData.diff_time);
              let time = second_time + "秒";
              if (parseInt(second_time) > 60) {
                var second = parseInt(second_time) % 60;
                var min = parseInt(second_time / 60);
                time = min + "分" + second + "秒";

                if (min > 60) {
                  min = parseInt(second_time / 60) % 60;
                  var hour = parseInt(parseInt(second_time / 60) / 60);
                  time = hour + "小时" + min + "分" + second + "秒";

                  if (hour > 24) {
                    hour = parseInt(parseInt(second_time / 60) / 60) % 24;
                    var day = parseInt(
                      parseInt(parseInt(second_time / 60) / 60) / 24
                    );
                    time = day + "天" + hour + "小时" + min + "分";
                  }
                }
              }
              this.goodsDetailData.djsTime = time;
              this.goodsDetailData.diff_time -= 1;
            }
          }, 1000);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.collectDetail {
  .detailCpjs {
    background-color: #fff;
    padding: 15px 0;
    font-size: 14px;

    p {
      padding: 0 15px;
    }

    img {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.collectDetail {
  .detailCon {
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 0px #eef1fd;
    padding-bottom: 14px;

    .top {
      width: 100%;
      position: relative;
      height: 375px;
      background-image: url(../assets/detailBg.png);
      background-size: 100% auto;
      background-repeat: no-repeat;

      .back {
        width: 16px;
        position: absolute;
        top: 17px;
        left: 15px;
      }

      .goodsZuo {
        width: 356px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .goodsImage {
        width: 230px;
        height: 230px;
        box-shadow: 0px 2px 7px 0px rgba(161, 161, 161, 0.5);
        border-radius: 10px;
        border: 2px solid #ffffff;
        position: absolute;
        top: 33px;
        left: 0;
        right: 0;
        margin: auto;
        animation: leftToRight linear 5.5s infinite;
      }

      @-webkit-keyframes leftToRight {
        0% {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }

        50% {
          transform: perspective(500px) rotateX(0deg) rotateY(-15deg);
        }

        to {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }
      }

      @keyframes leftToRight {
        0% {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }

        50% {
          transform: perspective(500px) rotateX(0deg) rotateY(-15deg);
        }

        to {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }
      }

      .goodsSq {
        position: absolute;
        bottom: 0;
        right: 13px;
        width: 50px;
      }

      .goodsQgz {
        width: 70px;
        position: absolute;
        right: 10px;
        bottom: 0;

        img {
          width: 100%;
        }
      }

      .goodsTs {
        position: absolute;
        bottom: 0;
        right: 10px;
        height: 32px;
        padding: 0 10px;
        background: #ffffff;
        border-radius: 5px;

        img {
          width: 14px;
          margin-right: 3px;
        }

        div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #333333;
        }
      }

      .goodsDjs {
        height: 32px;
        background: #ffffff;
        border-radius: 5px;
        position: absolute;
        right: 10px;
        bottom: 0;
        display: flex;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;

        .left {
          display: flex;
          justify-content: center;
          background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
          width: 60px;
          height: 100%;
          font-size: 12px;
          color: #333333;
          align-items: center;

          img {
            width: 10px;
            flex-shrink: 0;
            margin-right: 2px;
          }
        }

        .right {
          font-size: 12px;
          padding-left: 7px;
          padding-right: 10px;
          color: #333333;
        }
      }
    }

    .bot {
      padding-top: 18px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .botLeft {
        margin-left: 15px;
        font-size: 18px;
        color: #333333;
      }

      .botRight {
        margin-right: 15px;
        text-align: right;

        p {
          font-size: 22px;
          color: #333333;

          span {
            font-size: 14px;
          }
        }

        div {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .detailAuthor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #ffffff;
    margin-top: 10px;

    .autLeft {
      display: flex;
      align-items: center;

      .autImg {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .autName {
        p {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }

        img {
          margin-top: 4px;
          width: 26px;
        }
      }
    }

    .autRight {
      font-size: 12px;
      color: #666666;
    }
  }

  .detailPart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;

    img {
      width: 72px;
    }

    h1 {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      margin: 0 5px;
    }
  }

  .detailPart1 {
    margin-top: 30px;
  }

  .detailRzxx {
    background: #ffffff;
    padding: 15px;

    .rzxxItem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .rzxx-left {
        font-size: 14px;
        color: #999999;
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
      }

      .rzxx-center {
        flex: 1;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999999;
      }

      .rzxx-right {
        width: 16px;

        button {
          display: block;
          border: 0;
          outline: none;
          width: 100%;
          background-color: unset;

          img {
            width: 100%;
          }
        }
      }
    }

    .rzxxItem:last-child {
      margin-bottom: 0;
    }
  }

  .detailGmxz {
    padding: 30px;

    margin-top: 18px;
    background-color: #fff;

    .detailPart2 {
      margin-top: 0;
    }

    p {
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      margin-bottom: 26px;
    }

    .gmxzLjgm {
      width: 100%;
      height: 50px;
      background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
      border-radius: 5px;
      font-size: 16px;
      color: #333333;
      text-align: center;
      line-height: 50px;
    }
  }
}</style>